var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.titleDefined ? "comp" : "" }, [
    _c(
      "div",
      {
        staticClass:
          " v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted v-select v-text-field__slot",
      },
      [
        _c(
          "label",
          {
            staticClass: "v-label v-label--active theme--light",
            staticStyle: { left: "0px", right: "auto", position: "absolute" },
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        ),
        _c(
          "v-tooltip",
          {
            attrs: { right: "" },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "v-icon",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              color: _vm.color,
                              large: "",
                              readonly: "",
                            },
                            domProps: { textContent: _vm._s(_vm.icon) },
                          },
                          "v-icon",
                          attrs,
                          false
                        ),
                        on
                      )
                    ),
                  ]
                },
              },
            ]),
          },
          [_c("span", [_vm._v(_vm._s(_vm.tooltip))])]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }