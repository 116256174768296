var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Business Lines",
      headers: _vm.headers,
      data: _vm.businessLines,
      loading: _vm.isLoading,
      "col-props": ["Status", "ACOM"],
    },
    on: { "row-clicked": _vm.rowClicked },
    scopedSlots: _vm._u([
      {
        key: "column-Status",
        fn: function (props) {
          return [
            _c("pga-business-line-status", {
              model: {
                value: props.row.item.Status,
                callback: function ($$v) {
                  _vm.$set(props.row.item, "Status", $$v)
                },
                expression: "props.row.item.Status",
              },
            }),
          ]
        },
      },
      {
        key: "column-ACOM",
        fn: function (props) {
          return [
            _c(
              "pga-partial-content",
              {
                attrs: {
                  "content-length": props.row.item.ACOM.length,
                  chips: "",
                },
              },
              _vm._l(props.row.item.ACOM.slice(0, 3), function (acom, i) {
                return _c("v-chip", { key: i }, [
                  _vm._v(" " + _vm._s(acom.FullName) + " "),
                ])
              }),
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }