var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditBusinessLineACOM" } },
            [_c("pga-save-button", { on: { click: _vm.Edit } })],
            1
          ),
          _c("pga-cancel-button", { on: { click: _vm.undo } }),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.BusinessLineEdit.BusinessLineEditView } },
        [
          _c("pga-business-line-acom-edit", {
            model: {
              value: _vm.BusinessLineEdit.BusinessLineEditView,
              callback: function ($$v) {
                _vm.$set(_vm.BusinessLineEdit, "BusinessLineEditView", $$v)
              },
              expression: "BusinessLineEdit.BusinessLineEditView",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }