var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mb-12", attrs: { tile: "" } },
    [
      _c("v-card-title", [
        _vm._v("Details Business Line " + _vm._s(_vm.title)),
      ]),
      _c("v-divider"),
      _c(
        "v-form",
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Code", readonly: "" },
                        model: {
                          value: _vm.code,
                          callback: function ($$v) {
                            _vm.code = $$v
                          },
                          expression: "code",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("pga-text-field", {
                        attrs: { label: "Title", readonly: "" },
                        model: {
                          value: _vm.title,
                          callback: function ($$v) {
                            _vm.title = $$v
                          },
                          expression: "title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6" } },
                    [
                      _c("pga-select", {
                        attrs: {
                          "item-text": "FullName",
                          "item-value": "Id",
                          items: _vm.ACOM,
                          label: "ACOM",
                          multiple: "",
                          readonly: "",
                        },
                        model: {
                          value: _vm.ACOM,
                          callback: function ($$v) {
                            _vm.ACOM = $$v
                          },
                          expression: "ACOM",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "2" } },
                    [
                      _c("pga-business-line-status", {
                        attrs: { title: "Status" },
                        model: {
                          value: _vm.status,
                          callback: function ($$v) {
                            _vm.status = $$v
                          },
                          expression: "status",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }